import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import { GatsbyImage } from "gatsby-plugin-image";
import Call from "../components/Call";
import parse from "html-react-parser";

function replaceImgByGatsbyImage(reactElement, mapping) {
  if (Array.isArray(reactElement)){
    return reactElement.map(elem => replaceImgByGatsbyImage(elem, mapping));
  }
  else if (reactElement.type === "img") {
    const regex =
      /https:\/\/directus\.server\.jolhof\.de\/assets\/([^\?]+)\?.*width=(\d+).*height=(\d+)/gm;
    let m = regex.exec(reactElement.props.src);
    let style = (reactElement.props.style)? reactElement.props.style: {};
    style["height"] = `${m[3]}px`;
    style["width"] = `${m[2]}px`;
    return (m[2] < 1000)?<GatsbyImage image={mapping[m[1]]} alt={reactElement.props.alt} style={style}/>:<GatsbyImage image={mapping[m[1]]} alt={reactElement.props.alt} />;

  } else if (reactElement.props && reactElement.props.children) {
    if (Array.isArray(reactElement.props.children)) {
      const children = reactElement.props.children.map((obj) =>
        replaceImgByGatsbyImage(obj, mapping)
      );
      const props = { ...reactElement.props, children };
      return { ...reactElement, props };
    } else if (
      typeof reactElement.props.children === "object" &&
      reactElement.props.children !== null
    ) {
      const children = replaceImgByGatsbyImage(
        reactElement.props.children,
        mapping
      );
      const props = { ...reactElement.props, children };
      return { ...reactElement, props };
    }
  }
  return reactElement;
}

const Hof = (context) => {
  const { data } = context;
  const article = data.directus.Artikel_by_id;
  const { title, showContact, path, kurzbeschreibung } = article;
  const html = article.text;
  const endbox = false;
  const startbild = article.image;
  const image = startbild ? startbild.imageFile.childImageSharp : null;
  let splitted = 0;
  let formatedEndbox = 0;
  if (endbox) {
    splitted = endbox.split("-");
    formatedEndbox = splitted.slice(1).map((obj) => <li key={obj}>{obj}</li>);
  }
  const image_options = data.directus_system.files.reduce(
    (a, { id, imageFile }) => ({
      ...a,
      [id]: imageFile.childImageSharp.gatsbyImageData,
    }),
    {}
  );
  let reactElement = replaceImgByGatsbyImage(parse(html), image_options);
  return (
    <Layout bodyClass="page-hof">
      <SEO
        title={title}
        image={image ? image.fixed : null}
        description={kurzbeschreibung}
        slug={path}
      />
      <div className={startbild ? "" : "strip strip-white strip-diagonal"}>
        {startbild && (
          <div className="container">
            <GatsbyImage image={image.normal} alt="" />
          </div>
        )}

        <div className="container pt-4 pt-md-10">
          <div className="row justify-content-start">
            <div className="col-12 col-md-12">
              <div className="service service-single">
                <h1 className="title">{title}</h1>
                <div className="content">{reactElement}</div>
                {endbox && (
                  <div className="call">
                    <div className="container">
                      <b>{splitted[0]}</b>
                      <ul>{formatedEndbox}</ul>
                    </div>
                  </div>
                )}
                {showContact && <Call />}
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: ID!) {
    directus {
      Artikel_by_id(id: $id) {
        path
        date_created
        date_updated
        title
        date
        showContact
        kurzbeschreibung
        text
        image {
          imageFile {
            childImageSharp {
              normal: gatsbyImageData(
                layout: CONSTRAINED
                width: 1400
                height: 400
              )
              fixed: gatsbyImageData(layout: FIXED, width: 1200)
            }
            absolutePath
          }
          id
        }

        featured
      }
    }
    directus_system {
      files(filter: { type: { _starts_with: "image/" } }) {
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1200)
          }
        }
      }
    }
  }
`;

export default Hof;
