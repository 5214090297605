import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const waMessage = "Hallo, ich will gern ein Fleischpaket bestellen."
const waMessageEnc = escape(waMessage);

const Call = props => (
  <div className="call">
    <div className="call-box-top">
      <div className="call-phone">
        <strong>Telefon: </strong>
        {props.data.site.siteMetadata.contact.phone}
      </div>
      <div className="call-whatsapp">
        <strong>WhatsApp: </strong>
        <a href={`https://wa.me/49${props.data.site.siteMetadata.contact.mobilephone.slice(1)}?text=${waMessageEnc}`}>
        {props.data.site.siteMetadata.contact.mobilephone}
        </a>   
      </div>
      <div className="call-email">
        <strong>Email: </strong>
        <a href={`mailto:${props.data.site.siteMetadata.contact.email}`}>
          {props.data.site.siteMetadata.contact.email}
        </a>
      </div>
      <div className="call-address">
        <strong>Anschrift: </strong>
         {props.data.site.siteMetadata.contact.address}
         <br/>
         {props.data.site.siteMetadata.contact.addressTown}
      </div>
    </div>
    {props.button && (
      <div className="call-box-bottom">
        <a href="/kontakt" className="button">
          Kontakt
        </a>
      </div>
    )}
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            contact {
              email
              phone
              mobilephone
              address
              addressTown
            }
          }
        }
      }
    `}
    render={data => <Call button={props.button} data={data} />}
  />
);
